import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import EmailIcon from '@mui/icons-material/Email';
import BusinessIcon from '@mui/icons-material/Business';
import Map from "../buildingMap.jpg"
import { Divider } from '@mui/material';

const ContactUs = () => {
    return (
        <Box paddingX={{xs: 5, sm: 8, lg: 20, xl: 30}} paddingY={8} display={{xs:'flex', md: 'block'}} justifyContent={'center'} color='white'>

            <Box display={{xs:'inline-block', md: 'flex'}}>
              <Box width="100%" id='contact-us'>
              <Typography variant="h2" color="white" fontSize={'24px'} textAlign="center" marginBottom={3} fontWeight={600} fontFamily={'DM Sans'} letterSpacing={2.5}>
              CONTACT US
            </Typography>

              <Box display={'flex'} justifyContent={'center'} marginBottom={7}>
                <Divider color={'#73FF8D'} width={'75%'} style={{ height:'1.5px', maxWidth:'550px' }} />
              </Box>

                <Box textAlign='center' fontFamily={'DM Sans'} marginBottom={{xs: 5, md: 0}}>
                    <Box marginBottom={3}>
                      <BusinessIcon/>
                      <Typography> 1200 Brickell ave, Suite 1660, Zip 33131, FL, Usa  </Typography>
                      <Typography> Suite 1660 </Typography>
                      <Typography> Zip 33131, FL, Usa </Typography>
                    </Box>

                    <Box marginBottom={2}>
                      <EmailIcon />
                      <Typography> info@multitronikllc.com </Typography>
                    </Box>
                  <Divider/>
                </Box>

              </Box>

              <Box width={'100%'} marginX={{xs: 0, md: 2 }} textAlign={{xs: 'center', xl: 'right'}} >
                  <img src={Map} width={'80%'} style={{borderRadius:'10px'}}/>      
              </Box>
          </Box>

      </Box>
    )
}

export default ContactUs;