import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material'

const AboutUs = () => {

    return (
        <Box paddingY={6} paddingX={{xs: 5, sm: 8, lg: 20, xl: 30}} display={{xs:'inline-block', md: 'block'}} id="about-us" color={'white'} >
          <Box width={'100%'}>
            <Typography variant="h2" color="white" fontSize={'24px'} textAlign="center" marginBottom={3} fontWeight={600} fontFamily={'DM Sans'} letterSpacing={2.5}>
              ABOUT US
            </Typography>

            <Box display={'flex'} justifyContent={'center'} marginBottom={4}>
              <Divider color={'#73FF8D'} width={'85%'} style={{ height:'1.5px', maxWidth:'550px' }} />
            </Box>

            <Box marginBottom={2}>

              <Typography fontSize={{xs: '16px', lg: '18px'}} fontFamily={'DM Sans'} marginBottom={1}>
              Welcome to <strong>Multitronik LLC</strong>, your trusted partner for cutting-edge low voltage contractor services.
              </Typography>
              <Typography fontSize={{xs: '16px', lg: '18px'}} fontFamily={'DM Sans'} marginBottom={1}>
              We are a forward-thinking company dedicated to delivering innovative, efficient, and reliable solutions for all your projects.
              </Typography>
              <Typography fontSize={{xs: '16px', lg: '18px'}} fontFamily={'DM Sans'} marginBottom={3}>
              Our commitment to excellence, technical expertise, and customer satisfaction sets us apart in the industry.
              </Typography>
              <Typography fontSize={{xs: '16px', lg: '18px'}} fontFamily={'DM Sans'} marginBottom={1}>
              At <strong>Multitronik LLC</strong>, we are a team of experienced professionals who specialize in low voltage systems.
              </Typography>
              <Typography fontSize={{xs: '16px', lg: '18px'}} fontFamily={'DM Sans'} marginBottom={3}>
               Our diverse skill set and extensive industry knowledge allows us to tackle a wide range of projects, from residential installations to large-scale commercial ventures.
              </Typography>
              <Typography fontSize={{xs: '16px', lg: '18px'}} fontFamily={'DM Sans'} >
              We are passionate about what we do and are driven by a relentless pursuit of perfection in every project we undertake.
              </Typography>

            </Box>
          </Box>
      </Box>
    )
}

export default AboutUs;