import React, { useEffect } from 'react';
import Policy from './components/Policy';
import Aup from './components/Aup';
import Bsa from './components/Bsa';
import MainPage from './pages/MainPage';
import "@fontsource/dm-sans/400.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

const App = () => {
  
  return (

    <Router>
      <Routes>
        {/* App routes */}
        <Route path="" element={<MainPage/>} />
        <Route path="/policy.html" element={<Policy/>} />
        <Route path="/legal/aup" element={<Aup/>} />
        <Route path="/legal/bsa" element={<Bsa/>} />
        <Route path="/documents" element={<Bsa/>} />
        <Route path="/documents/doc" element={<MainPage/>} />
        <Route path="/documents/onboarding.pdf" element={<MainPage/>} />
        <Route path="*" element={<MainPage/>} />
      </Routes>
    </Router>

  );
}


export default App;
