import { Box, Button, Card, Chip, Container, Divider, Grid, Modal, Typography } from "@mui/material";


const Footer = () => {
    return (
        <>
        <Box width={'100%'} marginBottom={2}>
            <Divider color={'white'} width={'100%'} style={{ height:'1.5px' }} />
        </Box>

        <Typography color='white' display={'flex'} justifyContent={'center'} marginBottom={4} fontSize={'14px'}>
            Copyright © 2023 Multitronik LLC.
        </Typography>
        </>
    )
}

export default Footer;