import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Logo from '../navBarLogo2.png';
import "@fontsource/dm-sans/400-italic.css";

const NavBar = () => {
    const pages = ['HOME', 'ABOUT US', 'PROJECTS', 'CONTACT US'];
    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
      };
      const handleCloseNavMenu = () => {
        setAnchorElNav(null);
      };

      
  const onClickOnMenuItem = (page) => {
    scrollToSection(page.toLowerCase().replace(' ', '-'))
  }

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: 'smooth',
      });
      handleCloseNavMenu();
    }
  };
    
return (
    <>
    
    <Container maxWidth="4500px" sx={{padding: {xs: '15px 15px', sm: '8px 35px 8px 45px'}}}>

        <Toolbar disableGutters>
          <Box sx={{ display: { xs: 'none', sm: 'flex' }, mr: 1 }} >
            <img src={Logo} width={'125px'}/>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', sm: 'none' }, width:'100%' }}>
            <img src={Logo} width={'125px'}/>
            <Menu
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: '',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: '',
              }}
              slotProps={{
                paper: {
                  sx: {
                    width: '100%',
                    maxWidth: 'inherit !important',
                    left: '0px !important',
                    top: '85px !important'
                  },
                }
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none', color:'red' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page} onClick={()=> onClickOnMenuItem(page)}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ display: { xs: 'flex', sm: 'none' }, mr: 0}}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="#2A2A2B"
              >
                <MenuIcon />
              </IconButton>
          </Box>
      
          <Box sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex', justifyContent: 'end' } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => onClickOnMenuItem(page)}
                sx={{ my: 2, mr: 1.5, color: '#2A2A2B', display: 'block', fontSize: {xs: '11px', lg: '14px', xl: '16px'}, fontWeight: 700 }}
              >
                {page}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            
          </Box>
        </Toolbar>
      </Container>
      </>
)
}

export default NavBar;