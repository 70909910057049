import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material'

const FeatureCard = ({title, content, customStyles}) => {

    return (
        <Box 
            width={customStyles?.width ?? '80%'} 
            height={customStyles?.height ?? '90%'}
            border={'solid black 2px'} 
            borderRadius={'10px'} 
            padding={'15px'} 
            marginBottom={{xs: 0, sm: 5}} 
            marginLeft={customStyles?.marginLeft ?? '0'}
            sx={{
              ':hover': {
                backgroundColor: '#222222', // theme.palette.primary.main
                color: 'white',
              },
            }}
            >
          <Typography fontWeight={700} fontFamily={'DM Sans'} fontSize={'20px'} marginBottom={2}>{title}</Typography>
          <Typography fontFamily={'DM Sans'} fontSize={{xs: '0.9em'}}>{content}</Typography>
        </Box>

    )
}

export default FeatureCard;