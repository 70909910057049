import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ClosingMulti from '../ClosingMulti.png';
import { Divider } from '@mui/material'

const Closing = () => {

    return (
        <Box paddingY={4} paddingX={{xs: 5, sm: 8, lg: 20, xl: 30}} backgroundColor='#F1F1F2' display={{xs:'inline-block', md: 'flex'}}>
          <Box width={'100%'} id="about-us" marginTop={2}>

            <Box display={{xs: 'inline-block', sm: 'flex'}} flexDirection={'row-reverse'}>
                <Box width={'100%'} display={'flex'} justifyContent={{xs: 'center', sm:'flex-start'}} alignItems={'flex-start'} marginBottom={{xs: '50px'}} minWidth={{xs: 'inherit', sm: '270px'}} maxWidth={'500px'}>
                    <img src={ClosingMulti} width={'100%'} style={{objectFit:'cover', objectPosition:'1px', minWidth:'200px', borderRadius: '10px', height:'100%'}}/>    
                </Box>
                <Box width={'100%'} paddingRight={4}>
                    <Typography fontSize={'20px'} fontFamily={'DM Sans'}  marginBottom={{xs: 3, sm: 2, xl: 4}} marginTop={0}>
                    At <strong>Multitronik LLC.</strong> we thrive on challenges and embrace opportunities to create intelligent, secure, and connected environments. <br/> <br/> The <strong>Aston Martin Residences</strong> project represents our dedication to delivering innovative solutions that redefine modern living. 
                    </Typography>
                    <Typography fontWeight={900} fontSize={'24px'} marginBottom={4}>Contact us today to discuss how we can elevate your project with our expertise.</Typography>
                </Box>
            </Box>
          </Box>
      </Box>
    )
}

export default Closing;