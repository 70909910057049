import React, { useEffect } from 'react';

const Aup = () => {
  // Perform the redirection when the component mounts
  useEffect(() => {
    // Redirect to the external URL
    window.location.href = 'merce';
  }, []);

  // Return null as the component has no other content
  return null;
};

export default Aup;
