import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Multitronik from '../Multitronik2.png';
import HeroBack from '../HeroBack.png';

const Hero = () => {

    return (
        <Box 
             id='home'
             display={{xs: 'inline-block', sm: 'flex'}}  
             sx={{
              justifyContent:{xs: 'center', md: 'inherit'},
              width: '100%',
              height: '100%',
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url(${HeroBack})`,
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat'
            }}
            >
          <Box width={{xs: '100%', sm: '70%'}}  display={{xs: 'flex', sm: 'inline-block'}} justifyContent={{xs: 'center', sm: 'inherit'}}>
            <Typography variant="h2"
                        color="white" 
                        sx={{ marginLeft: {xs: 0, sm: 10}, fontSize: {xs: '35px', sm: '50px', lg: '70px'}}} 
                        marginTop={15} marginBottom={{xs: 7, sm: 15 }} 
                        fontSize={'2rem'} 
                        fontFamily={'DM Sans'} 
                        fontWeight={700}
                        letterSpacing={2}
                        >
                        
                TECHNOLOGICAL <br/> SOLUTIONS <br/> IN MULTIPLE <br/> DIMENSIONS
              </Typography>     
          </Box>

          <Box width={{xs: '100%', sm: '30%'}} marginRight={{xs: 5}} display={'flex'} justifyContent={{xs: 'right', sm:'center'}} alignItems={'flex-end'}>
            <Box marginRight={{xs: '10px', sm: 0}} marginBottom={{xs: 0, sm:'10px'}}width={{xs: '60%', sm: '100%'}} minWidth={'200px'}>
              <img src={Multitronik} objectFit={'contain'} width={'100%'}/>  
            </Box>
          </Box>

      </Box>
    )
}

export default Hero;